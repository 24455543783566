import { Subscription } from "./Subscription";
import { Fee } from "./Fee";
import { DateUtils } from 'react-frontend-utils'
import { ThemeColors } from "../Theme";

export class PayoutAct {

    //ISO_3166-1_alpha-2 official country codes - used by Stripe
    static Country = {

        US:     {code: "US", bit: 0, label: "United States"},
        CA:     {code: "CA", bit: 0, label: "Canada"},
        UK:     {code: "GB", bit: 0, label: "United Kingdom"},
        AU:     {code: "AU", bit: 0, label: "Australia"},
    }


    id;
    dbaName;
    type;
    payoutsEnabled;
    country;
    created;

    externalAccounts;

    constructor(json) {
        this.id = json.id;
        this.dbaName = json.dbaName;
        this.type = json.type;
        this.payoutsEnabled = json.payoutsEnabled;

        if (json.created)
            this.created = DateUtils.parseJsonDate(json.created, true);

        this.country = PayoutAct.countryFromString(json.country);

        this.externalAccounts = json.externalAccounts ? json.externalAccounts : [];
    }

    static countryFromString(str) {
        for (let index in PayoutAct.Country) {
            const country = PayoutAct.Country[index];

            if (country.code === str) {
                return country;
            }
        }  
        return null;
    }

    compare = (other) => {
        if (this.dbaName === other.dbaName) 
            return 0;
        return this.dbaName > other.dbaName ? 1 : -1;
    }
}


export class Subscriber {
    subscription;
    lastTransactionIssueDate;

    constructor(json) {
        this.subscription = new Subscription(json.subscription);
        if (json.lastTransactionIssueDate)
            this.lastTransactionIssueDate = DateUtils.parseJsonDate(json.lastTransactionIssueDate, false);  // leave UTC
    }
}

export class AccountSnapshot {
    id;          
    date;
    snapshotBalance; 
    pastDue;
    prevStatementBalance;
    extendedName;           // not always populated
    billingEmail;           // not always populated
    payoutMode;
    autoPayOn;              // not always populated

    constructor(json) {
        if (json) { 
            this.accountNo = json.id.accountNo;
            this.date = DateUtils.parseJsonDate(json.id.date, true); //convert from UTC            

            this.snapshotBalance = json.snapshotBalance;
            this.pastDue = json.pastDue;
            this.prevStatementBalance = json.prevStatementBalance;
            this.extendedName = json.extendedName;
            this.billingEmail = json.billingEmail;
            this.payoutMode = json.payoutMode;
            this.autoPayOn = json.autoPayOn;
        }
    }

}

export class AccountAutoPayment {
    accountNo;
    stripeCustomerId;
    paymentDetails;
    acceptedOn;

    constructor(json) {
        if (json) {
            this.accountNo = json.accountNo;
            this.stripeCustomerId = json.stripeCustomerId;
            this.paymentDetails = json.paymentDetails;
            this.acceptedOn = json.acceptedOn;
        }
    }
}

export class TaxInfo {
    taxpayerID;                       // Taxpayer ID (SSN or EIN)
    tinType;                          // SSN or EIN     
    taxMailingAddress;                // semicolon seperated field: payee;line1;line2;city;state;zip;country
    taxClassification;                // LLC, S-Corp, etc.
    exemptFrom1099;                   // Not required to send a 1099
    effectiveDate;   
    signature;                        // Signature of the taxpayer         

    constructor(json) {
        if (json) {
            this.taxpayerID = json.taxpayerID;
            this.tinType = json.tinType;
            this.taxMailingAddress = json.taxMailingAddress;
            this.taxClassification = json.taxClassification;
            this.exemptFrom1099 = json.exemptFrom1099;
            this.effectiveDate = DateUtils.parseJsonDate(json.effectiveDate, false);
            this.signature = json.signature;
        }
    }   

    // return the first part of the address
    entity() {
        return this.taxMailingAddress.split(";")[0];
    }

    // remove the entity, and replace semicolons with spaces
    formatAddress() {
        return this.taxMailingAddress.split(";").slice(1).join(" ");
    }

}


export class Account {


    static State = {

        ACTIVE:     {enumName: "ACTIVE", bit: 0, label: "Active", tooltip: "Active, fully functional", color: ThemeColors.activeGreen},  
        SUSPENDED:  {enumName: "SUSPENDED", bit: 1, label: "Suspended", tooltip: "Suspended for non-payment / terms non-compliance (Membership portal access suspended)", color: ThemeColors.suspendedOrange},  
        CLOSED:     {enumName: "CLOSED", bit: 2, label: "Closed", tooltip: "Account is closed", color: ThemeColors.closedRed},  
        TEST:       {enumName: "TEST", bit: 3, label: "Test", tooltip: "A test account / not an actual client (removed from statistics)", color: ThemeColors.testPurple},  
        TRIAL:      {enumName: "TRIAL", bit: 4, label: "Trial", tooltip: "Trial account, fully functional", color: ThemeColors.trialBlue},
        VOID:       {enumName: "VOID", bit: 5, label: "Void", tooltip: "Expired trial account or duplicate account (removed from statistics)", color: ThemeColors.voidedGray},
    };

    static PayoutMode = {

        MANUAL:                     {enumName: "MANUAL", bit: 0, label: "Off", tooltip: "No automatic payouts."},  
        AUTO_PAPER_CHECK:           {enumName: "AUTO_PAPER_CHECK", bit: 1, label: "Auto - Paper Check", tooltip: "Check automatically mailed monthly to the address on file"},  
        AUTO_ELECTRONIC_MONTHLY:    {enumName: "AUTO_ELECTRONIC_MONTHLY", bit: 2, label: "Auto - Electronic Monthly", tooltip: "Electronic transfer to Connected Account made monthly"},  
        AUTO_ELECTRONIC_DAILY:      {enumName: "AUTO_ELECTRONIC_DAILY", bit: 3, label: "Auto - Electronic Daily", tooltip: "Electronic transfer to Connected Account made daily"},  
    };

    //---OKTA Fields
    accountNo;
    groupName;
    extendedName;
    address;
    maxUsers;
    isoCurrency;
    marketEnabled;
    checkInDisabled;                                
    ePassEnabled;
    bookingEnabled; 
    //--------------

    paymentHmac;

    accountRep;
    accountRepName;
    accountRepEmail;
    accountRepUrl;

    hoc;
    hocName;
    hocEmail;
    hocCCEmails;

    state;
    seasonal;

    hasClientApiKey;

    frontendAccountNotes;
    frontendBillingNotes;
    frontendNextActionsNotes;
    frontendYearAcquired;
    billingEmails;  
    autoStatements;
    autoPaymentEnabled;

    payoutMode;
    stripeConnectedAccountId;
    payoutCheckMailingAddress;  // semicolon seperated field: payee;line1;line2;city;state;zip;country
    billingMailingAddress;      // semicolon seperated field: payee;line1;line2;city;state;zip;country

    taxHistory;                 // array of tax info objects
 
    resetRefundLimit;
    currentRefundLimit;
    
    fees;               //Fee object array (custom and defaulta)
    subscribers;        //Subscription object array


    constructor(json) {
        if (json) {    
            this.accountNo = json.accountNo;
            this.groupName = json.groupName;
            this.extendedName = json.extendedName;
            this.address = json.address;
            this.maxUsers = json.maxUsers;
            this.isoCurrency = json.isoCurrency;
            this.marketEnabled = json.marketEnabled;
            this.checkInDisabled = json.checkInDisabled;
            this.ePassEnabled = json.ePassEnabled;
            this.bookingEnabled = json.bookingEnabled;
            
            this.paymentHmac = json.paymentHmac;

            this.accountRep = json.accountRep;
            this.accountRepName = json.accountRepName;
            this.accountRepEmail = json.accountRepEmail;
            this.accountRepUrl = json.accountRepUrl;
            this.hoc = json.hoc;
            this.hocName = json.hocName;
            this.hocEmail = json.hocEmail;
            this.hocCCEmails = json.hocCCEmails;

            this.state = Account.stateFromString(json.state);
            this.seasonal = json.seasonal;
            this.hasClientApiKey = json.hasClientApiKey;

            this.frontendAccountNotes = json.frontendAccountNotes;
            this.frontendBillingNotes = json.frontendBillingNotes;
            this.frontendNextActionsNotes = json.frontendNextActionsNotes;
            this.frontendYearAcquired = json.frontendYearAcquired;
            this.billingEmails = json.billingEmails;
            this.autoStatements = json.autoStatements;
            this.autoPaymentEnabled = json.autoPaymentEnabled;
            this.payoutMode = Account.payoutModeFromString(json.payoutMode);
            this.stripeConnectedAccountId = json.stripeConnectedAccountId;
            this.payoutCheckMailingAddress = json.payoutCheckMailingAddress;
            this.billingMailingAddress = json.billingMailingAddress;

            if (json.taxHistory)
                this.taxHistory = json.taxHistory.map(taxInfo => new TaxInfo(taxInfo));
            else
                this.taxHistory = [];

            this.resetRefundLimit = json.resetRefundLimit;
            this.currentRefundLimit = json.currentRefundLimit;
            
            if (json.subscribers)
                this.subscribers = json.subscribers.map(subscriber => new Subscriber(subscriber));
            else
                this.subscribers = [];

            if (json.fees)
                this.fees = json.fees.map(fee => new Fee(fee));
            else
                this.fees = [];
        }
    }

    hasSubscription = (subscription) => {
        const names = this.subscribers.map(subscribers => subscribers.subscription.name);
        return names.includes(subscription.name);
    }

    currentTaxInfo = () => {
        if (this.taxHistory.length === 0) {
            return new TaxInfo();
        }
        //return the last tax info object in the array
        return this.taxHistory[this.taxHistory.length - 1];
    }

    static stateFromString(str) {
        for (let index in Account.State) {
            const state = Account.State[index];

            if (state.enumName === str) {
                return state;
            }
        }  
        return null;
    }

    static payoutModeFromString(str) {
        for (let index in Account.PayoutMode) {
            const mode = Account.PayoutMode[index];

            if (mode.enumName === str) {
                return mode;
            }
        }  
        return null;
    }

    static payoutModeIsElectronic(mode) {
        return mode.enumName.includes("ELECTRONIC");
    }

    static payoutModeIsMailedCheck(mode) {
        return mode.enumName.includes("PAPER_CHECK");
    }

}





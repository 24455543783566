import React, { Component } from 'react';
import { Container, Popover, Button, TextField, Box, Paper, Typography, Checkbox } from '@material-ui/core'

/**
 * Create a new account with and manage basic settings or edit an existing account's basic settings.
 * 
 * Props:
 * isOpen: boolean - whether the popover is open
 * account: object - the account to edit, or null if creating a new account
 * suggestedAccountNumber: string - a suggested account number for a new account, ignored if editing an existing account
 * okCallback: function - called when the user presses the OK button, with the new basic settings
 * cancelCallback: function - called when the user presses the Cancel button
 */
export class BasicSettingsPopover extends Component {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10
        }
    };

 
    _okCallback;
    _cancelCallback;

    state = {
        extendedName: "",
        maxUsers: 30,
        address: "",
        checkInDisabled: false,
        ePassEnabled: false,
        bookingEnabled: false,
        databaseName: "",
        accountNo: "",
        isoCurrency: "USD"
    };
    
    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
        if (props.account) {
            this.state.extendedName = props.account.extendedName;
            this.state.maxUsers = props.account.maxUsers;
            this.state.address = props.account.address;
            this.state.checkInDisabled = props.account.checkInDisabled;
            this.state.ePassEnabled = props.account.ePassEnabled;
            this.state.bookingEnabled = props.account.bookingEnabled;
        }
        if (props.suggestedAccountNumber)
            this.state.accountNo = props.suggestedAccountNumber;
    }
   

    _okButtonPressed = () => {

        const basicSettings = {
            extendedName: this.state.extendedName,
            maxUsers: this.state.maxUsers,
            address: this.state.address,
            checkInDisabled: this.state.checkInDisabled,
            ePassEnabled: this.state.ePassEnabled,
            bookingEnabled: this.state.bookingEnabled,
        }

        // Add these fields if creating a new account
        if (!this.props.account) {
            basicSettings.accountNo = this.state.accountNo;
            basicSettings.databaseName = this.state.databaseName;
            basicSettings.isoCurrency = this.state.isoCurrency;
        }

        this._okCallback(basicSettings);
    }

    _cancelButtonPressed = () => {
        this._cancelCallback();
    }
    
    
    render() {
        
        let maxWidth = window.innerWidth * 0.5;  //half the window, up to 700
        if (maxWidth > 700)
            maxWidth = 700;
        
        const title = this.props.account ? "Edit Account " + this.props.account.accountNo + " Basic Settings" : "Create New Account";
        const okText = this.props.account ? "Ok" : "Create";
        
        let settingsVaid = this.state.extendedName && this.state.extendedName.length > 0 && this.state.maxUsers > 0;

        if (!this.props.account)
            settingsVaid = settingsVaid && this.state.accountNo && this.state.accountNo.length === 8 && this.state.databaseName && this.state.databaseName.length > 0 && this.state.isoCurrency && this.state.isoCurrency.length === 3;

        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                <div style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>{title}</Typography>  

                    {this.props.account ? null :
                        <Paper style={{padding: 10, marginBottom: 30}}>
                            <TextField variant="outlined" size="small" InputLabelProps={{ shrink: true}} label="New Account Number" value={this.state.accountNo} onChange={(e) => this.setState({accountNo: e.target.value})} fullWidth />
                            <Typography variant="caption" align='left' style={{color: 'gray', fontStyle: 'italic'}}>Numeric Value, typically 8 digits: 2 digit year acquired and 6 digit unique number</Typography>
                            
                            <TextField variant="outlined" size="small" style={{marginTop: 20}} InputLabelProps={{ shrink: true}} label="New Database Name" value={this.state.databaseName} onChange={(e) => this.setState({databaseName: e.target.value})} fullWidth />
                            <Typography variant="caption" align='left' style={{color: 'gray', fontStyle: 'italic'}}>Lowercase letters, numbers, and underscore only (less than 50 characters)</Typography>

                            <TextField variant="outlined" size="small" style={{display: 'block', marginTop: 20}}  InputLabelProps={{ shrink: true}} label="ISO Currency" value={this.state.isoCurrency} onChange={(e) => this.setState({isoCurrency: e.target.value.toUpperCase()})}/>
                            <Typography variant="caption" align='left' style={{color: 'gray', fontStyle: 'italic'}}>3-letter ISO Currency (cannot be changed later)</Typography>

                        </Paper>
                    }    

                    <TextField variant="outlined" size="small" InputLabelProps={{ shrink: true}} label="Extended Name" value={this.state.extendedName} onChange={(e) => this.setState({extendedName: e.target.value})} fullWidth style={{marginBottom: 20}} />
                    <TextField variant="outlined" size="small" InputLabelProps={{ shrink: true}} label="Address" value={this.state.address} onChange={(e) => this.setState({address: e.target.value})} fullWidth style={{marginBottom: 20}} />
                    <TextField variant="outlined" size="small" InputLabelProps={{ shrink: true}} label="Max Users" type="number" value={this.state.maxUsers} onChange={(e) => this.setState({maxUsers: e.target.value})} fullWidth style={{marginBottom: 20, width: 150}} />
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginBottom: 10}}>
                        <Checkbox checked={!this.state.checkInDisabled} color='primary'
                                  onChange={(event) => { this.setState({checkInDisabled: !event.target.checked})}}/>   
                        <Typography variant='body2' align='left' style={{color: 'gray'}}>Enable Membership Check-In</Typography>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginBottom: 10}}>
                        <Checkbox checked={this.state.ePassEnabled} color='primary'
                                  onChange={(event) => { this.setState({ePassEnabled: event.target.checked})}}/>   
                        <Typography variant='body2' align='left' style={{color: 'gray'}}>Enable ePasses</Typography>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginBottom: 10}}>
                        <Checkbox checked={this.state.bookingEnabled} color='primary'
                                  onChange={(event) => { this.setState({bookingEnabled: event.target.checked})}}/>   
                        <Typography variant='body2' align='left' style={{color: 'gray'}}>Enable Bookings</Typography>
                    </div> 

                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!settingsVaid} color="primary" variant="outlined" style={this.styles.button}>{okText}</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </div>
            </Popover>
        );
        
        
    }


}
import { Tooltip } from '@material-ui/core'

import { ThemeColors } from '../Theme'
import { DateUtils, Currency, } from 'react-frontend-utils'

import { Global } from '../models/Global'
import { OpenInNewTab, OpenExternalInNewTab } from '../App'

export class Transaction {

    static Type = {

        PATRON_SALE:     {enumName: "PATRON_SALE", bit: 0, label: "Patron Sale", backgroundColor: ThemeColors.clientCreditGreen, 
                          manualDescription: "A sale to a Patron that AGS facilitated outside the portals. Credits the client account. Very rarely ever used.",
                          manualDescriptionColor: 'red', 
                          tooltip: "Collection of money on your behalf from your Patron. This is a credit to your account (negative amount)."},  
        PATRON_REFUND:   {enumName: "PATRON_REFUND", bit: 1, label: "Patron Refund", backgroundColor: ThemeColors.refundYellow, 
                          manualDescription: "Refund of money to a Patron, for instance an AGS check to a Patron. This is a charge to the client account. Most refunds should be done through the portals or Stripe.",
                          manualDescriptionColor: 'red', 
                          tooltip: "Money refunded to a Patron. This is a charge to your account"},
        AGS_CHARGE:      {enumName: "AGS_CHARGE", bit: 2, label: "AGS Charge", backgroundColor: ThemeColors.chargeRed, 
                          manualDescription: "Charge to the client not handled anywhere else",
                          manualDescriptionColor: 'gray', 
                          tooltip: "Charge to you for a service or item AGS provided"},
        AGS_CREDIT:      {enumName: "AGS_CREDIT", bit: 3, label: "AGS Credit", backgroundColor: ThemeColors.creditPurple, 
                          manualDescription: "Refund/credit to the client, for instance, to account for an erroneous charge",
                          manualDescriptionColor: 'gray', 
                          tooltip: "A credit to your statement (negative value is a credit)"},
        PAYMENT:         {enumName: "PAYMENT", bit: 4, label: "Payment", backgroundColor: ThemeColors.paymentBlue, 
                          manualDescription: "Unusual payment by a client (not a check or credit card). Example: cash. Very rarely ever used.",
                          manualDescriptionColor: 'red', 
                          tooltip: "Your payment to AGS. This credits your acccount (negative amount)"},
        PAYOUT:          {enumName: "PAYOUT", bit: 5, label: "Payout", backgroundColor: ThemeColors.payoutOrange, 
                          manualDescription: "Payout to a client not handled automatically",
                          manualDescriptionColor: 'gray', 
                          tooltip: "Our payout to you when you have an account credit"},
        PATRON_DISPUTE:  {enumName: "PATRON_DISPUTE", bit: 6, label: "Patron Dispute", backgroundColor: ThemeColors.disputeOrange, 
                          manualDescription: "A dispute from the Patron on a charge. Use for testing only.",
                          manualDescriptionColor: 'red', 
                          tooltip: "Charges disputed by your Patron. This is a charge to your account, but the funds are returned if you win the dispute."}
    };
    
    
      
    id;
    type;
    date;               //Converted to local
    live; 
    accountNo;
    amount;
    agsCost;            //superadmin only

    detail;             //includes subType, and fields specific to each detail

    constructor(json) {
        if (json) {        
            this.id = json.id;
            this.type = Transaction.typeFromString(json.type);                 
            this.date = DateUtils.parseJsonDate(json.date, true); //convert from UTC            
            this.live = json.live;
            this.accountNo = json.accountNo;
            this.detail = json.detail;
            this.amount = json.amount;
            this.agsCost = json.frontendCost;
        }
    }

    
    
    static typeFromString(str) {
        for (let index in Transaction.Type) {
            const type = Transaction.Type[index];

            if (type.enumName === str) {
                return type;
            }
        }  
        return null;
    }
    
    static jumpToStripePayment = (payment) => {
        const istest = window.location.hostname.startsWith("localhost") ? "test/" : "";
        OpenExternalInNewTab("https://dashboard.stripe.com/" + istest + "payments/" + payment);         
    }

    static jumpToStripeTransfer = (ref) => {
        const istest = window.location.hostname.startsWith("localhost") ? "test/" : "";
        OpenExternalInNewTab("https://dashboard.stripe.com/" + istest + "connect/transfers/" + ref);         
    }
    

    static detailStyle = {
        transactionPage: {backgroundColor: ThemeColors.transactionDetailTan, marginLeft: 50, padding: 8},
        statement: {marginLeft: 50, padding: 8},
        popup: {},
        id: {fontWeight: 'bold', marginBottom: 4},
        transactionPageExplanation: {fontStyle: 'italic', fontSize: 12, textAlign: 'right', marginRight: 50},
        popupExplanation: {fontStyle: 'italic', fontSize: 12, marginTop: 10},
        statementExplanation: {display: 'none'}

    }

    //Render the subdetail, with optional transaction click
    renderSubDetail(explanationStyle, onTransactionClick) {

        const transactionLink = (text, id) => {
            const linkStyle = {marginLeft: 5, cursor: 'pointer', textTransform: 'none', textDecoration: 'underline', color: 'blue', fontWeight: 'normal'};
            return (
                <div style={{display: 'flex', marginRight: 'auto'}}>
                    <div>{text}</div>
                    <div style={onTransactionClick ? linkStyle : null} onClick={onTransactionClick ? () => onTransactionClick(id) : null}>{id}</div>                
                </div>
            ); 
        }

        const applicationLink = (text, id) => {
            const linkStyle = {marginLeft: 5, cursor: 'pointer', textTransform: 'none', textDecoration: 'underline', color: 'blue', fontWeight: 'normal'};
            const url = "https://applications.accessgrantedsystems.net/agportal?id=" + id;
            return (
                <div style={{display: 'flex', marginRight: 'auto'}}>
                    <div>{text}</div>
                    <div style={linkStyle} onClick={() => OpenInNewTab(url)}>{id}</div>                
                </div>
            ); 
        }

        const bookingEventLink = (text, id) => {
            const linkStyle = {marginLeft: 5, cursor: 'pointer', textTransform: 'none', textDecoration: 'underline', color: 'blue', fontWeight: 'normal'};
            const url = "https://booking.accessgrantedsystems.net/bkportal?slotid=" + id;
            return (
                <div style={{display: 'flex', marginRight: 'auto'}}>
                    <div>{text}</div>
                    <div style={linkStyle} onClick={() => OpenInNewTab(url)}>{id}</div>                
                </div>
            ); 
        }

        const paymentIntentLink = (id) => {
            const linkStyle = {marginLeft: 5, cursor: 'pointer', textTransform: 'none', textDecoration: 'underline', color: 'blue', fontWeight: 'normal'};
            return (
                <div style={{display: 'flex', marginRight: 'auto'}}>
                    <div>Payment Tracking ID:</div> 
                    {Global.isAGSAccountAdmin() ?
                        <div style={linkStyle} onClick={() => Transaction.jumpToStripePayment(id)}>{id}</div>
                        :      
                        <div style={{marginLeft: 5}}>{id}</div>
                    }           
                </div>
            ); 
        }

        const stripeTransferLink = (id) => {
            const linkStyle = {marginLeft: 5, cursor: 'pointer', textTransform: 'none', textDecoration: 'underline', color: 'blue', fontWeight: 'normal'};
            return (
                <div style={{display: 'flex', marginRight: 'auto'}}>
                    <div>Transfer Reference ID:</div> 
                    {Global.isAGSAccountAdmin() ?
                        <div style={linkStyle} onClick={() => Transaction.jumpToStripeTransfer(id)}>{id}</div>
                        :      
                        <div style={{marginLeft: 5}}>{id}</div>
                    }           
                </div>
            ); 
        }
     

        let subTypeExplanation;

        //The Java subclass of TransactionDetail 
        switch (this.detail.detailClass) {

            //--------------------------------------------------------------
            case "Application Service Fee":

                switch (this.detail.subType) {
                    case "APPLICATION PROCESSING":
                        subTypeExplanation = "This fee was triggered because a Patron submitted an Application electronically (full service)";
                        break;
                    case "SELF PROCESSING":
                        subTypeExplanation = "This fee was triggered because a Patron submitted an Application electronically (self service)";
                        break;
                    case "PAPER PROCESSING":
                        subTypeExplanation = "This fee was triggered when AGS received and uploaded a Paper Application";
                        break;
                    case "MERGED DOCUMENT":
                        subTypeExplanation = "This fee was triggered when a merged document was created from an Application";
                        break;
                    default:
                        subTypeExplanation = "Unknown explanation";
                        break;
                }

                return (
                    <div>
                        {this.detail.applicationId ? applicationLink("Application ID:", this.detail.applicationId) : null}
                        <div>{"Applicant Email: " + this.detail.applicantEmail}</div>
                        <div>{"Applicant Address: " + this.detail.applicantAddress}</div>
                        <div style={explanationStyle}>{subTypeExplanation}</div>
                    </div>
                );
   
            //--------------------------------------------------------------
            case "Manual Transaction":
                return (
                    <div>
                        <div>{"Processed by : " + this.detail.processedBy}</div>
                        <div>{"Note: " + this.detail.note}</div>
                        {this.detail.correctedTransactionId ? transactionLink("Corrected Transaction Reference:", this.detail.correctedTransactionId) : null}
                        <div style={explanationStyle}>This was a manually entered Transaction by AGS</div>
                    </div>
                );
     
            //--------------------------------------------------------------
            case "Patron Sale":

                let saleIDInfo;
                switch (this.detail.subType) {
                    case "ONLINE APPLICATION":
                    case "PAPER APPLICATION":
                        if (this.detail.saleID)
                            saleIDInfo = applicationLink("Application ID:", this.detail.saleID)
                        if (this.type === Transaction.Type.PATRON_SALE)
                            subTypeExplanation = "We collected money on your behalf from the Patron when they submitted the Application";
                        else
                            subTypeExplanation = "We refunded money on your behalf to the Patron that was collected on the Application";
                        break;
                    case "SUPPORT REQUEST":
                        saleIDInfo = "Support Request ID: " + this.detail.saleID;
                        if (this.type === Transaction.Type.PATRON_SALE)
                            subTypeExplanation = "We collected money on your behalf because the Patron made a payment in the Support Request";
                        else
                            subTypeExplanation = "We refunded money on your behalf to the Patron that was collected on the Support Request";
                        break;
                    case "MARKETPLACE":
                        saleIDInfo = "Marketplace Ticket Number: " + this.detail.saleID;
                        if (this.type === Transaction.Type.PATRON_SALE)
                            subTypeExplanation = "We collected money on your behalf because the Patron made a deposit/puchase in the Marketplace";
                        else
                            subTypeExplanation = "We refunded money on your behalf to the Patron from a Marketplace ticket";
                        break;
                    case "INVOICE":
                        saleIDInfo = "Invoice Number: " + this.detail.saleID;
                        if (this.type === Transaction.Type.PATRON_SALE)
                            subTypeExplanation = "We collected money on your behalf because the Patron paid the Invoice you sent them";
                        else
                            subTypeExplanation = "We refunded money on your behalf to the Patron from Invoice they paid";
                        break;
                    case "CUSTOM ORDER":
                        saleIDInfo = "Order Number: " + this.detail.saleID;
                        subTypeExplanation = "We collected money on your behalf because the Patron placed a custom order";
                        break;
                    case "BOOKING":
                        if (this.detail.saleID)
                            saleIDInfo = bookingEventLink("Booking ID:", this.detail.saleID)
                        if (this.type === Transaction.Type.PATRON_SALE)
                            subTypeExplanation = "We collected money on your behalf because the Patron booked your Service";
                        else
                            subTypeExplanation = "We refunded money on your behalf to the Patron from a Booking";
                        break;
                    case "EVENT SIGNUP":
                        if (this.detail.saleID)
                            saleIDInfo = bookingEventLink("Event ID:", this.detail.saleID)
                        if (this.type === Transaction.Type.PATRON_SALE)
                            subTypeExplanation = "We collected money on your behalf because the Patron signed up for your Event";
                        else
                            subTypeExplanation = "We refunded money on your behalf to the Patron from a Event signup";
                        break;
                    default:
                        subTypeExplanation = "Unknown explanation";
                        saleIDInfo = "Unknown subType";
                        break;
                }
                return (
                    <div>
                        <div>{saleIDInfo}</div>
                        <div>{"Patron Name: " + (this.detail.patronName ? this.detail.patronName : "")}</div>
                        <div>{"Patron Email: " + (this.detail.patronEmail ? this.detail.patronEmail : "")}</div>
                        <div>{"Patron Address: " + (this.detail.patronAddress ? this.detail.patronAddress : "")}</div>
                        <div>{"Membership: " + (this.detail.membershipID ? this.detail.membershipID : "")}</div>
                        {this.detail.paymentIntent ? <div>{"Payment Tracking ID: " + this.detail.paymentIntent}</div> : null}
                        <div>{"Sale Amount: " + Currency.round(this.detail.saleAmount) + " " + this.detail.saleCurrency }</div>
                        <div style={explanationStyle}>{subTypeExplanation}</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Payment Processing Fee":
    
                return (
                    <div>
                        {transactionLink("Patron Sale Transaction Reference:", this.detail.saleTransactionId)}
                        <div>{"Percentage on sale amount: " + Currency.round(this.detail.amountMultipler * 100, 3) + "%"}</div>
                        <div>{"Fixed fee: $" + this.detail.fixedFee}</div>
                        <div style={explanationStyle}>This fee was charged on the amount we collected from a Patron on your behalf in the referenced Transaction</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Patron Custom Order Processing":

                return (
                    <div>
                        {transactionLink("Patron Sale Transaction Reference:", this.detail.saleTransactionId)}
                        <div>{"Processing fee: $" + this.detail.processingFee}</div>
                        <div>{"Description: " + this.detail.description}</div>
                        <div style={explanationStyle}>This fee was charged to process the Patron custom order</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Print Service Fee":
                let reference;
                switch (this.detail.subType) {
                    case "APPLICATION":
                        reference = "Triggered from new Application with ID: " + this.detail.reference;
                        break;
                    case "MANUAL":
                        reference = "Requested through PoolPass";
                        break;
                    default:
                        reference = "Unknown subType";
                        break;
                }
                
                return (
                    <div>
                        <div>{reference}</div>
                        <div>{"Membership ID: " + this.detail.membershipID}</div>
                        <div>{"Print Job: " + this.detail.printJobId}</div>
                        <div>{"Passes Printed: " + this.detail.printCount}</div>
                        <div>{"Mail destination: " + (this.detail.bulkMail ? "Community" : "Patron")}</div>
                        <div style={explanationStyle}>This fee was charged for physical passes printed</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Mail Service Fee":
                let type;
                let info;
                let detailExplanation;

                switch (this.detail.subType) {
                    case "PATRON":
                        type = "Printed passes mailed to Patron (Membership ID: " + this.detail.membershipID + ")";
                        info = "Print Job: " + this.detail.printJobId;
                        detailExplanation = "This fee was charged for mailing physical passes to the Patron's address";
                        break;
                    case "BULK":
                        type = "Printed passes bulk mailed to Community";
                        info = "Requested by: " + this.detail.user;
                        detailExplanation = "This fee was charged for mailing physical passes in bulk to the community";
                        break;
                    case "COMMUNITY":
                        type = "Community";
                        break;
                    default:
                        reference = "Unknown subType";
                        break;
                }
                
                return (
                    <div>
                        <div>{type}</div>
                        <div>{info}</div>
                        <div style={explanationStyle}>{detailExplanation}</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Purchase Order":
                const category = "Category: " + this.detail.category;
                const description = "Description: " + this.detail.description;
                const orderedBy = "Ordered by: " + this.detail.orderEmail;
                
                return (
                    <div>
                        <div>{category}</div>
                        <div>{description}</div>
                        <div>{orderedBy}</div>
                        <div style={explanationStyle}>You submitted a Purchase Order</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Subscription Charge":

                const nextOn = DateUtils.parseJsonDate(this.detail.nextOn, false); //convert from UTC
                return (
                    <div>
                        <div>{"Subscription: " + (this.detail.isMonthly ? "Monthly" : "Annually") + (this.detail.isFirst ? ", first installment" : "") + (this.detail.isProrated ? ", prorated" : "")}</div>
                        <div>{"Description: " + this.detail.description}</div>
                        <div>{"Next Transaction on: " + DateUtils.dateFormat(nextOn)}</div>
                        <div style={explanationStyle}>This fee was charged from a recurring subscription</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Payment Received":

                let payIntent;
                let payInfo;
                let checkDateStr;
                if (this.detail.checkDate) {
                    const date = DateUtils.parseJsonDate(this.detail.checkDate, true);  //UTC (no time)
                    checkDateStr = "Your check was dated: " + DateUtils.dateFormat(date);
                }
                switch (this.detail.subType) {
                    case "AUTOPAY ONLINE CARD":
                        subTypeExplanation = "Auto-pay: your saved payment method was automatically charged";
                        payInfo = this.detail.receiptEmail ? "Receipt emailed to: " + this.detail.receiptEmail : "No email provided for receipt";
                        payIntent = paymentIntentLink(this.detail.paymentIntent);
                        break;
                    case "ONLINE CARD":
                        subTypeExplanation = "You made an electronic payment online";
                        payInfo = this.detail.receiptEmail ? "Receipt emailed to: " + this.detail.receiptEmail : "No email provided for receipt";
                        payIntent = paymentIntentLink(this.detail.paymentIntent);
                        break;
                    case "CHECK":
                        subTypeExplanation = "You made a payment by check";
                        payInfo = "Your check no: " + this.detail.checkNo;
                        break;
                    default:
                        subTypeExplanation = "Unknown explanation";
                        payInfo = "Unknown subType";
                        break;
                }
                return (
                    <div>
                        {payIntent ? payIntent : null}
                        <div>{payInfo}</div>
                        {this.detail.invoiceNo ? <div>{"Invoice No: " + this.detail.invoiceNo}</div> : null}
                        {checkDateStr ? <div>{checkDateStr}</div> : null}
                        {this.detail.processedBy ? <div>{"Processed by: " + this.detail.processedBy}</div> : null}
                        {this.detail.note ? <div>{"Notes: " + this.detail.note}</div> : null}
                        <div style={explanationStyle}>{subTypeExplanation}</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Payout Disbursed":

      
                switch (this.detail.subType) {
                    case "MANUAL":
                        subTypeExplanation = "A manual payout was transfered to your connected account";
                        break;
                    case "AUTO PAPER CHECK":
                        subTypeExplanation = "A scheduled payout by paper check was sent to you by mail";
                        break;
                    case "AUTO ELECTRONIC MONTHLY":
                    case "AUTO ELECTRONIC DAILY":
                        subTypeExplanation = "A scheduled payout was transfered to your connected account";
                        break;
                    default:
                        subTypeExplanation = "Unknown explanation";
                        payInfo = "Unknown subType";
                        break;
                }
                return (
                    <div>
                        {this.detail.connectedAccountId ? <div>{"Transfer to Connected Account: " + this.detail.connectedAccountId}</div> : null}
                        {this.detail.electronicTransferId ? stripeTransferLink(this.detail.electronicTransferId) : null}
                        {this.detail.payoutNoticeNo ? <div>{"Payout Notice No: " + this.detail.payoutNoticeNo}</div> : null}
                        {this.detail.user ? <div>{"Triggered by: " + this.detail.user}</div> : null}
                        {this.detail.note ? <div>{"Note: " + this.detail.note}</div> : null}
                        <div style={explanationStyle}>{subTypeExplanation}</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Dispute Fee":
    
                return (
                    <div>
                        {transactionLink("Dispute Transaction Reference:", this.detail.disputeTransactionId)}
                        <div>{"Percentage on dispute amount: " + Currency.round(this.detail.amountMultipler * 100, 3) + "%"}</div>
                        <div>{"Fixed fee: $" + this.detail.fixedFee}</div>
                        <div style={explanationStyle}>This fee was charged for negotiating the Patron dispute</div>
                    </div>
                );

            //--------------------------------------------------------------
            case "Patron Dispute":  

                switch (this.detail.subType) {
                    case "OPENED":
                        subTypeExplanation = "A patron opened a case disputing a charge. The disputed funds are on hold at the Patron's bank.";
                        break;
                    case "WON":
                        subTypeExplanation = "You won the dispute with your Patron. The disputed funds have been returned to you.";
                        break;
                    case "LOST":
                        subTypeExplanation = "You lost the dispute with your Patron. The disputed funds will remain with the Patron.";
                        break;
                    default:
                        subTypeExplanation = "Unknown explanation";
                        break;
                }
                return (
                    <div>
                        <div>{"Dispute Reference ID: " + this.detail.disputeID}</div>
                        {transactionLink("Disputed Transaction:", this.detail.transactionID)}
                        <div>{"Original Payment Tracking ID: " + this.detail.paymentIntent}</div>
                        <div>{"Dispute Amount: " + Currency.round(this.detail.chargebackAmount) + " " + this.detail.chargebackCurrency }</div>
                        <div>{"Reason: " + this.detail.reason}</div>
                        <div style={explanationStyle}>{subTypeExplanation}</div>
                    </div>
                );

            case "Payout Failure Credit":
                return (
                    <div>
                        {transactionLink("Payout Transaction:", this.detail.payoutTransactionId)}
                        <div>{"Reason: " + this.detail.reason}</div>
                        <div style={explanationStyle}>A credit to your account because our Payout Transaction failed</div>
                    </div>
                );

            //--------------------------------------------------------------
            default:
                return <div>{"Unknown Detail Class: " + this.detail.detailClass}</div>

        }
    }

    renderDetailForStatement() {
        return <div style={Transaction.detailStyle.statement}>
                    {this.renderSubDetail(Transaction.detailStyle.statementExplanation, null)}
                </div>;
    }

    renderDetailForTransactionList(onTransactionClick) {
        return <div style={Transaction.detailStyle.transactionPage}>
                    <div style={Transaction.detailStyle.id}>{"Transaction ID: " + this.id}</div>
                    {this.renderSubDetail(Transaction.detailStyle.transactionPageExplanation, onTransactionClick)}
                </div>;
    }

    renderDetailForPopup(onTransactionClick) {
        return <div style={Transaction.detailStyle.popup}>
                    {this.renderSubDetail(Transaction.detailStyle.popupExplanation, onTransactionClick)}
                </div>;
    }

    static badgeStyle = {
        testing: {
            alignContent: 'center',
            width: 14,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: '50%', 
            color: 'white', 
            textAlign: 'center',
            fontSize: 12
        },
    }

    renderBadges(justify = 'left') {
        return <div style={{display: 'flex', justifyContent: justify, gap: 3}}>
                    <Tooltip title={this.testing ? "This is a test transaction" : ""}>
                        <div style={{...Transaction.badgeStyle.testing, backgroundColor: !this.live ? 'red' : 'white'}}>
                            {!this.live ? "T" : ""}
                        </div>
                    </Tooltip>
                </div>;
    }

}


